<template>
    <!-- 作品页面路由 -->
    <div class="center_works_box">
        <!-- 上半部分 -->
        <div class="center_works_top">
            <!-- 上传相册 -->
            <label for="uploadWorks">上传相片</label>
            <input 
                type="file" 
                id="uploadWorks" 
                class="center_works_upload" 
                accept="image/png,image/jpeg"/>
            <!-- 批量操作按钮 -->
            <el-button type="text">批量操作</el-button>
            <!-- 返回按钮 -->
            <el-button 
                type="text" 
                icon="el-icon-d-arrow-left"
                @click="$router.back()">返回</el-button>
        </div>
        <hr>
        <!-- 作品部分 -->
        <div class="center_works_bottom">
            <el-row :gutter="20">
                <el-col 
                    :span="6" 
                    v-for="item in 10"
                    :key="item.id">
                    <a href="javascript:;">
                        <el-card :body-style="{ padding: '0px' }">
                            <div class="center_works_bottom_img">
                                <img :src="require('@/assets/images/lensman/rBACE1Xf2GiDWRtEAAIyHllkybA426_600x.jpg')" alt="">
                            </div>
                        </el-card>
                    </a>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>
/* element-ui */
.center_works_bottom .el-col{
    margin-bottom: 20px;
}

/* 上传按钮 */
.center_works_top>label{
    margin-right: 10px;
    padding: 10px 30px;
    border-radius: 5px;
    font-weight: normal;
    color: #fff;
    background-color: #E6A23C;
    cursor:pointer;
}

/* 下半部分 */
.center_works_bottom_img{
    position: relative;
    width: 100%;
    overflow: hidden;
}
.center_works_bottom_img::before{
    content: "";
    padding-top: 100%;
    display: block;
}
.center_works_bottom_img>img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
}
</style>
